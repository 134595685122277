import React from "react"
import * as styles from "../styles/portfolio.module.css"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Cross from "../assets/cross.inline.svg"
import BlobSketch from "../assets/blob_sketch.inline.svg"

const Description = ({ closeDescription, descriptionIndex }) => {
  const { t } = useTranslation()
  return (
    <>
      {descriptionIndex === "ph1" && (
        <div className={styles.photoDescription}>
          <button
            onClick={e => {
              closeDescription(e)
            }}
            className="closeDesc"
            aria-label={t("PORTFOLIO.CLOSE_PHOTOGRID_BUTTON")}
            title={t("PORTFOLIO.CLOSE_PHOTOGRID_BUTTON")}
          >
            <Cross className="cross" />
          </button>
          <div className={styles.descriptionWrapper}>
            <h3 style={{ textAlign: "center" }}>
              {t("PORTFOLIO.PHOTOGRID_HEADER_ONE")}
            </h3>
            <span>
              <p>{t("PORTFOLIO.PHOTOGRID_PARAGRAPH_ONE1")}</p>
              <a
                href="https://www.figma.com/"
                target="_blank"
                rel="noreferrer noopener"
                title={t("PORTFOLIO.PHOTOGRID.FIGMA_TITLE")}
                aria-label={t("PORTFOLIO.PHOTOGRID.FIGMA_TITLE")}
              >
                Figma
              </a>
              <p>{t("PORTFOLIO.PHOTOGRID_PARAGRAPH_ONE2")}</p>
              <a
                href="https://www.gatsbyjs.com/"
                aria-label={t("WEB.OFFICIAL_GATSBY_PAGE")}
                title={t("WEB.OFFICIAL_GATSBY_PAGE_TITLE")}
                target="_blank"
                rel="noreferrer noopener"
              >
                Gatsby.js
              </a>
              <p>{t("PORTFOLIO.PHOTOGRID_PARAGRAPH_ONE3")}</p>
            </span>
            <BlobSketch />
            <span>
              <p>{t("PORTFOLIO.PHOTOGRID_PARAGRAPH_ONE4")}</p>
              <a
                href="https://greensock.com/"
                target="_blank"
                rel="noreferrer noopener"
                title="GreenSock animation platform"
                aria-label="GreenSock animation platform"
              >
                GSAP
              </a>
              <p>.</p>
            </span>
          </div>
        </div>
      )}
      {descriptionIndex === "ph2" && (
        <div className={styles.photoDescription}>
          <button
            onClick={e => {
              closeDescription(e)
            }}
            className="closeDesc"
            aria-label={t("PORTFOLIO.CLOSE_PHOTOGRID_BUTTON")}
            title={t("PORTFOLIO.CLOSE_PHOTOGRID_BUTTON")}
          >
            <Cross className="cross" />
          </button>
          <div className={styles.descriptionWrapper}>
            <h3 style={{ textAlign: "center" }}>
              {t("PORTFOLIO.PHOTOGRID_HEADER_TWO")}
            </h3>
            <span>
              <p>{t("PORTFOLIO.PHOTOGRID_PARAGRAPH_TWO1")}</p>
              <a
                href="https://coolors.co"
                aria-label={t("PORTFOLIO.PHOTOGRID_COOLORS_TITLE")}
                title={t("PORTFOLIO.PHOTOGRID_COOLORS_TITLE")}
                target="_blank"
                rel="noreferrer noopener"
              >
                coolors.co
              </a>
              <p>{t("PORTFOLIO.PHOTOGRID_PARAGRAPH_TWO2")}</p>
            </span>
            <div className={styles.paletteFrame}>
              <div>
                <p class="mediumLabel">FLIRT</p>
              </div>
              <div>
                <p class="mediumLabel">PRINCETON</p>
              </div>
              <div>
                <p class="mediumLabel">VERDIGRIS</p>
              </div>
              <div>
                <p class="mediumLabel">MUNSELL</p>
              </div>
              <div>
                <p class="mediumLabel">AQUAMARINE</p>
              </div>
            </div>
            <span>
              <p>{t("PORTFOLIO.PHOTOGRID_PARAGRAPH_TWO3")}</p>
              <a
                href="https://mdigi.tools/color-shades"
                target="_blank"
                rel="noreferrer noopener"
              >
                Color Shades Generator
              </a>
              <p>{t("PORTFOLIO.PHOTOGRID_PARAGRAPH_TWO4")}</p>
            </span>
            <div className={styles.shadeFrame}>
              <div>
                <div>
                  <p class="mediumLabel">100</p>
                </div>
                <div>
                  <p class="mediumLabel">200</p>
                </div>
                <div>
                  <p class="mediumLabel">300</p>
                </div>
                <div>
                  <p class="mediumLabel">400</p>
                </div>
                <div>
                  <p class="mediumLabel">500</p>
                </div>
                <div>
                  <p class="mediumLabel">600</p>
                </div>
                <div>
                  <p class="mediumLabel">700</p>
                </div>
                <div>
                  <p class="mediumLabel">800</p>
                </div>
                <div>
                  <p class="mediumLabel">900</p>
                </div>
              </div>
              <div>
                <div>
                  <p class="mediumLabel">100</p>
                </div>
                <div>
                  <p class="mediumLabel">200</p>
                </div>
                <div>
                  <p class="mediumLabel">300</p>
                </div>
                <div>
                  <p class="mediumLabel">400</p>
                </div>
                <div>
                  <p class="mediumLabel">500</p>
                </div>
                <div>
                  <p class="mediumLabel">600</p>
                </div>
                <div>
                  <p class="mediumLabel">700</p>
                </div>
              </div>
              <div>
                <div>
                  <p class="mediumLabel">100</p>
                </div>
                <div>
                  <p class="mediumLabel">200</p>
                </div>
                <div>
                  <p class="mediumLabel">300</p>
                </div>
                <div>
                  <p class="mediumLabel">400</p>
                </div>
                <div>
                  <p class="mediumLabel">500</p>
                </div>
                <div>
                  <p class="mediumLabel">600</p>
                </div>
                <div>
                  <p class="mediumLabel">700</p>
                </div>
              </div>
              <div>
                <div>
                  <p class="mediumLabel">LIGHTER</p>
                </div>
                <div>
                  <p class="mediumLabel">MUNSELL</p>
                </div>
              </div>
              <div>
                <div>
                  <p class="mediumLabel">100</p>
                </div>
                <div>
                  <p class="mediumLabel">200</p>
                </div>
                <div>
                  <p class="mediumLabel">300</p>
                </div>
                <div>
                  <p class="mediumLabel">400</p>
                </div>
                <div>
                  <p class="mediumLabel">500</p>
                </div>
                <div>
                  <p class="mediumLabel">600</p>
                </div>
                <div>
                  <p class="mediumLabel">700</p>
                </div>
                <div>
                  <p class="mediumLabel">800</p>
                </div>
                <div>
                  <p class="mediumLabel">900</p>
                </div>
              </div>
            </div>
            <div className={styles.typographyFrame}>
              <div>
                <div>
                  <p class="mediumLabel">100</p>
                </div>
                <div>
                  <p class="mediumLabel">200</p>
                </div>
                <div>
                  <p class="mediumLabel">300</p>
                </div>
                <div>
                  <p class="mediumLabel">400</p>
                </div>
                <div>
                  <p class="mediumLabel">500</p>
                </div>
              </div>
              <div>
                <div>
                  <p class="mediumLabel">100</p>
                </div>
                <div>
                  <p class="mediumLabel">200</p>
                </div>
                <div>
                  <p class="mediumLabel">300</p>
                </div>
                <div>
                  <p class="mediumLabel">400</p>
                </div>
                <div>
                  <p class="mediumLabel">500</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {descriptionIndex === "ph3" && (
        <div className={styles.photoDescription}>
          <button
            onClick={e => {
              closeDescription(e)
            }}
            className="closeDesc"
            aria-label={t("PORTFOLIO.CLOSE_PHOTOGRID_BUTTON")}
            title={t("PORTFOLIO.CLOSE_PHOTOGRID_BUTTON")}
          >
            <Cross className="cross" />
          </button>
          <div className={styles.descriptionWrapper}>
            <h3 style={{ textAlign: "center" }}>
              {t("PORTFOLIO.PHOTOGRID_HEADER_THREE")}
            </h3>
            <p>{t("PORTFOLIO.PHOTOGRID_PARAGRAPH_THREE1")}</p>
          </div>
        </div>
      )}
      {descriptionIndex === "ph4" && (
        <div className={styles.photoDescription}>
          <button
            onClick={e => {
              closeDescription(e)
            }}
            className="closeDesc"
            aria-label={t("PORTFOLIO.CLOSE_PHOTOGRID_BUTTON")}
            title={t("PORTFOLIO.CLOSE_PHOTOGRID_BUTTON")}
          >
            <Cross className="cross" />
          </button>
          <div className={styles.descriptionWrapper}>
            <h3 style={{ textAlign: "center" }}>
              {t("PORTFOLIO.PHOTOGRID_HEADER_FOUR")}
            </h3>
            <p>{t("PORTFOLIO.PHOTOGRID_PARAGRAPH_FOUR1")}</p>
          </div>
        </div>
      )}
      {descriptionIndex === "ph5" && (
        <div className={styles.photoDescription}>
          <button
            onClick={e => {
              closeDescription(e)
            }}
            className="closeDesc"
            aria-label={t("PORTFOLIO.CLOSE_PHOTOGRID_BUTTON")}
            title={t("PORTFOLIO.CLOSE_PHOTOGRID_BUTTON")}
          >
            <Cross className="cross" />
          </button>
          <div className={styles.descriptionWrapper}>
            <h3 style={{ textAlign: "center" }}>
              {t("PORTFOLIO.PHOTOGRID_HEADER_FIVE")}
            </h3>
            <ul>
              <p>{t("PORTFOLIO.PHOTOGRID_PARAGRAPH_FIVE1")}</p>
            </ul>
          </div>
        </div>
      )}
    </>
  )
}

export default Description
