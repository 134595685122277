import React, {
  useRef,
  useLayoutEffect,
  useState,
  useEffect,
  useContext,
} from "react"
import * as styles from "../styles/portfolio.module.css"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import { usePrefersReducedMotion } from "../components/prefersReducedMotion.js"
import { StaticImage } from "gatsby-plugin-image"
import useSound from "use-sound"
import ButtonClick from "../sounds/button-click.mp3"
import ToggleSound from "../sounds/toggle-button.mp3"
import { GlobalContext } from "../components/soundToggle.js"
import Description from "./photoGridDescription"

function initialTransform(element) {
  if (typeof window === `undefined`)
    return { angle: 0, scale: 1, offsetX: 0, offsetY: 0 }
  let st = window.getComputedStyle(element, null)
  const startPosition =
    st.getPropertyValue("transform") ||
    st.getPropertyValue("-moz-transform") ||
    st.getPropertyValue("-webkit-transform") ||
    st.getPropertyValue("-ms-transform") ||
    st.getPropertyValue("-o-transform")
  let values = startPosition.split("(")[1]
  values = values.split(")")[0]
  values = values.split(",")
  let a = values[0]
  let b = values[1]
  //let c = values[2]
  //let d = values[3]
  let e = values[4]
  let f = values[5]
  let scale = Math.sqrt(a * a + b * b)
  let angle = Math.round(Math.atan2(b, a) * (180 / Math.PI))
  let offsetX = Math.round(parseFloat(e))
  let offsetY = Math.round(parseFloat(f))
  // matrix(scaleX, skewY, skewX, scaleY, translateX, translateY)
  //let matrixPattern = /^\w*\((-?((\d+)|(\d*\.\d+)),\s*)*(-?(\d+)|(\d*\.\d+))\)/i
  //let matrixValue = []
  //let matrixCopy = startPosition.replace(/^\w*\(/, "").replace(")", "")
  return {
    angle: angle,
    scale: scale,
    offsetX: offsetX,
    offsetY: offsetY,
    matrix: startPosition,
  }
}

const PhotoGrid = ({ scalingFactor }) => {
  const prefersReducedMotion = usePrefersReducedMotion()
  const useSoundEnabled = useContext(GlobalContext)
  const soundEnabled = useSoundEnabled.soundEnabledStatus
  const { t } = useTranslation()
  const { language } = useI18next()
  const [play] = useSound(ButtonClick, {
    volume: 0.25,
    soundEnabled: soundEnabled,
  })
  const [play2] = useSound(ToggleSound, {
    volume: 0.25,
    soundEnabled: soundEnabled,
  })
  const [index, setIndex] = useState(null)
  const [descriptionIndex, setDescriptionIndex] = useState(null)
  const [currentPhoto, setCurrentPhoto] = useState(null)
  const [initialPosition, setInitialPosition] = useState(null)
  const [initialRotation, setInitialRotation] = useState(null)
  const [descriptionOpen, setDescriptionOpen] = useState(false)

  const animation = useRef(null)

  const setActiveState = e => {
    setDescriptionIndex(e.currentTarget.id)
    initialTransform(e.currentTarget)
    setCurrentPhoto(e.currentTarget)
    setInitialPosition(initialTransform(e.currentTarget).matrix)
    if (index === e.currentTarget.id) {
      setIndex(null)
    } else {
      setIndex(e.currentTarget.id)
      setDescriptionOpen(true)
      play2()
    }
  }

  useLayoutEffect(() => {
    let photoDescription = document.querySelector("[class*='photoDescription']")
    if (currentPhoto === null) return
    const translatedPosition = initialTransform(currentPhoto).matrix
    if (index !== null) {
      photoDescription.style.width = "0"
      photoDescription.style.padding = "0"
    } else {
      document.querySelector(".closeDesc").style.opacity = 0
    }

    if (animation.current) {
      animation.current.reverse()
    } else {
      currentPhoto.style.zIndex = 2
      animation.current = currentPhoto.animate(
        [
          { transform: `${initialPosition}` },
          { transform: `${translatedPosition}` },
        ],
        { duration: 400, easing: "ease-in" }
      )
    }
    animation.current.onfinish = function () {
      if (index !== null) {
        currentPhoto.style.zIndex = 2
        showDescription()
      } else {
        currentPhoto.style.zIndex = 1
      }
      animation.current = null
    }
    initialTransform(currentPhoto)
  }, [index])

  const showDescription = () => {
    let photoDescription = document.querySelector("[class*='photoDescription']")
    if (prefersReducedMotion !== true) {
      let showAnimation = photoDescription.animate(
        [
          { width: `0`, padding: "0px" },
          { width: `100%`, padding: "16px" },
        ],
        { duration: 300, easing: "ease-in", fill: "forwards" }
      )
      showAnimation.onfinish = function () {
        buttonFadeIn()
        textFadein()
        document.querySelector(".closeDesc").focus({
          preventScroll: true,
        })
      }
    } else {
      buttonFadeIn()
      textFadein()
      document.querySelector(".closeDesc").focus({
        preventScroll: true,
      })
    }
  }
  const hideDescription = () => {
    let photoDescription = document.querySelector("[class*='photoDescription']")
    let hideAnimation = photoDescription.animate(
      [
        { width: `100%`, padding: "16px" },
        { width: `0`, padding: "0" },
      ],
      { duration: 300, easing: "ease-out", fill: "forwards" }
    )
    hideAnimation.onfinish = function () {
      setDescriptionOpen(!descriptionOpen)
      currentPhoto.focus({
        preventScroll: true,
      })
    }
  }

  const buttonFadeIn = () => {
    let closeDesc = document.querySelector(".closeDesc")
    if (prefersReducedMotion !== true) {
      closeDesc.animate([{ opacity: `0` }, { opacity: `1` }], {
        duration: 400,
        easing: "ease-in-out",
        fill: "forwards",
      })
    } else {
      closeDesc.style.opacity = "1"
    }
  }

  const buttonFadeOut = () => {
    let closeDesc = document.querySelector(".closeDesc")
    if (prefersReducedMotion !== true) {
      closeDesc.animate([{ opacity: `1` }, { opacity: `0` }], {
        duration: 400,
        easing: "ease-in-out",
        fill: "forwards",
      })
    } else {
      closeDesc.style.opacity = "0"
    }
  }

  const textFadein = () => {
    let textContent = document.querySelector("[class*='photoDescription'] div")
    if (prefersReducedMotion !== true) {
      textContent.animate(
        [{ transform: "scaleY(0)" }, { transform: "scaleY(1)" }],
        { duration: 400, easing: "ease-out", fill: "forwards" }
      )
    } else {
      textContent.style.transform = "scaleY(1)"
    }
  }

  const textFadeOut = () => {
    let textContent = document.querySelector("[class*='photoDescription'] div")
    let hideText = textContent.animate(
      [{ transform: "scaleY(1)" }, { transform: "scaleY(0)" }],
      { duration: 300, easing: "ease-in", fill: "forwards" }
    )
    hideText.onfinish = function () {
      hideDescription()
    }
  }

  //click photos when pressing enter
  const handleEnterKey = e => {
    e.currentTarget.click()
  }
  const keyListenersMap = new Map([[13, handleEnterKey]])
  function keyListener(e) {
    // get the listener corresponding to the pressed key
    const listener = keyListenersMap.get(e.keyCode)
    // call the listener if it exists
    return listener && listener(e)
  }

  useEffect(() => {
    let photos = document.querySelectorAll("[class*='photo--']")

    photos.forEach(photo => {
      photo.addEventListener("keydown", keyListener)

      return () => photo.removeEventListener("keydown", keyListener)
    })
    photos.forEach(photo => {
      photo.addEventListener("focus", scrollTop)

      return () => photo.removeEventListener("keydown", scrollTop)
    })
  }, [])

  //prevent scrolling inside gridcontainer when using keyboard tab
  const scrollTop = () => {
    let gridContainer = document.querySelector(".gridContainer")
    gridContainer.scrollTo(0, 0)
  }

  const closeDescription = e => {
    play()
    document.querySelector("[class*='photoInFocus']").click()
    textFadeOut()
    buttonFadeOut()
  }

  let props = index ? { tabIndex: -1 } : { tabIndex: 0 }

  return (
    <>
      <a name="mosaic" TabIndex={-1}>
        <h2
          className={`${index == null ? "fadeIn" : "fadeOut"}`}
          style={{ marginTop: "0.3em" }}
        >
          {t("PORTFOLIO.PHOTOGRID_HEADLINE")}
        </h2>
      </a>
      <p
        style={{ marginBottom: "1em" }}
        className={`stylizedCapitalized ${
          index == null ? "fadeIn" : "fadeOut"
        }`}
      >
        {t("PORTFOLIO.PHOTOGRID_CLICK_ON_THE_PHOTOS")}
      </p>
      <div
        className="gridContainer"
        style={{
          zIndex: "10",
          width: "318px",
          height: "393px",
          overflow: "hidden",
          borderRadius: "30px",
          transform: `scale(${scalingFactor})`,
          transformOrigin: "top left",
        }}
      >
        <div className={styles.photoGridContainer}>
          <div
            id="ph1"
            {...props}
            onClick={e => {
              setActiveState(e)
            }}
            className={index === "ph1" ? styles.photoInFocus : styles.photo}
            title={t("PORTFOLIO.PHOTOGRID_SCREENSHOT_ONE_TITLE")}
          >
            {language === "da" ? (
              <StaticImage
                alt={t("PORTFOLIO.PHOTOGRID_SCREENSHOT_ONE_ALT")}
                src="../images/Ejaas_page_screenshot1_da.png"
                placeholder="blurred"
                layout="constrained"
                width={470}
                loading="lazy"
                style={{ borderRadius: "15px" }}
              />
            ) : (
              <StaticImage
                alt={t("PORTFOLIO.PHOTOGRID_SCREENSHOT_ONE_ALT")}
                src="../images/Ejaas_page_screenshot1_en.png"
                placeholder="blurred"
                layout="constrained"
                width={470}
                loading="lazy"
                style={{ borderRadius: "15px" }}
              />
            )}
          </div>
          <div
            id="ph2"
            {...props}
            onClick={e => {
              setActiveState(e)
            }}
            className={index === "ph2" ? styles.photoInFocus : styles.photo}
            title={t("PORTFOLIO.PHOTOGRID_SCREENSHOT_TWO_TITLE")}
          >
            {language === "da" ? (
              <StaticImage
                alt={t("PORTFOLIO.PHOTOGRID_SCREENSHOT_TWO_ALT")}
                src="../images/Ejaas_page_screenshot2_da.png"
                placeholder="blurred"
                layout="constrained"
                width={470}
                loading="lazy"
                style={{ borderRadius: "15px" }}
              />
            ) : (
              <StaticImage
                alt={t("PORTFOLIO.PHOTOGRID_SCREENSHOT_TWO_ALT")}
                src="../images/Ejaas_page_screenshot2_en.png"
                placeholder="blurred"
                layout="constrained"
                width={470}
                loading="lazy"
                style={{ borderRadius: "15px" }}
              />
            )}
          </div>
          <div
            id="ph3"
            {...props}
            onClick={e => {
              setActiveState(e)
            }}
            className={index === "ph3" ? styles.photoInFocus : styles.photo}
            title={t("PORTFOLIO.PHOTOGRID_SCREENSHOT_THREE_TITLE")}
          >
            {language === "da" ? (
              <StaticImage
                alt={t("PORTFOLIO.PHOTOGRID_SCREENSHOT_THREE_ALT")}
                src="../images/Ejaas_page_screenshot3_da.png"
                placeholder="blurred"
                layout="constrained"
                width={470}
                loading="lazy"
                style={{ borderRadius: "15px" }}
              />
            ) : (
              <StaticImage
                alt={t("PORTFOLIO.PHOTOGRID_SCREENSHOT_THREE_ALT")}
                src="../images/Ejaas_page_screenshot3_en.png"
                placeholder="blurred"
                layout="constrained"
                width={470}
                loading="lazy"
                style={{ borderRadius: "15px" }}
              />
            )}
          </div>
          <div
            id="ph4"
            {...props}
            onClick={e => {
              setActiveState(e)
            }}
            className={index === "ph4" ? styles.photoInFocus : styles.photo}
            title={t("PORTFOLIO.PHOTOGRID_SCREENSHOT_FOUR_TITLE")}
          >
            {language === "da" ? (
              <StaticImage
                alt={t("PORTFOLIO.PHOTOGRID_SCREENSHOT_FOUR_ALT")}
                src="../images/Ejaas_page_screenshot4_da.png"
                placeholder="blurred"
                layout="constrained"
                width={470}
                loading="lazy"
                style={{ borderRadius: "15px" }}
              />
            ) : (
              <StaticImage
                alt={t("PORTFOLIO.PHOTOGRID_SCREENSHOT_FOUR_ALT")}
                src="../images/Ejaas_page_screenshot4_en.png"
                placeholder="blurred"
                layout="constrained"
                width={470}
                loading="lazy"
                style={{ borderRadius: "15px" }}
              />
            )}
          </div>
          <div
            id="ph5"
            {...props}
            onClick={e => {
              setActiveState(e)
            }}
            className={index === "ph5" ? styles.photoInFocus : styles.photo}
            title={t("PORTFOLIO.PHOTOGRID_SCREENSHOT_FIVE_TITLE")}
          >
            {language === "da" ? (
              <StaticImage
                alt={t("PORTFOLIO.PHOTOGRID_SCREENSHOT_FIVE_ALT")}
                src="../images/Ejaas_page_screenshot5_da.png"
                placeholder="blurred"
                layout="constrained"
                width={470}
                loading="lazy"
                style={{ borderRadius: "15px" }}
              />
            ) : (
              <StaticImage
                alt={t("PORTFOLIO.PHOTOGRID_SCREENSHOT_FIVE_ALT")}
                src="../images/Ejaas_page_screenshot5_en.png"
                placeholder="blurred"
                layout="constrained"
                width={470}
                loading="lazy"
                style={{ borderRadius: "15px" }}
              />
            )}
          </div>
        </div>
      </div>
      {descriptionOpen && (
        <Description
          descriptionIndex={descriptionIndex}
          closeDescription={closeDescription}
        />
      )}
    </>
  )
}

export default PhotoGrid
