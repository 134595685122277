import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import BlobGradient from "../assets/blobWithGradient1.inline.svg"
import BlobClip from "../assets/clipBlob.inline.svg"
import Blob from "../assets/blob.inline.svg"
import {
  ListTLink,
  transitionFadeOut,
  transitionFadeIn,
} from "../components/layout.js"
import * as styles from "../styles/portfolio.module.css"
import Dotpattern from "../assets/dot_pattern.inline.svg"
import SwipeDown from "../assets/swipe_down_anim.inline.svg"
import ScrollDown from "../assets/scroll_down_anim.inline.svg"
import NextArrow from "../components/nextArrow.js"
import PreviousArrow from "../components/previousArrow.js"
import LanguageSwitch from "../components/languageSwitch.js"
import ThemeSwitch from "../components/themeSwitch.js"
import SoundButton from "../components/soundToggle.js"
import PhotoGrid from "../components/photoGrid.js"
import Page02 from "../assets/page02.inline.svg"
import Seo from "../components/seo.js"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import { gsap } from "gsap/dist/gsap"
import { usePrefersReducedMotion } from "../components/prefersReducedMotion.js"
import {
  floating,
  swipeDown,
  scrollDownMouse,
} from "../components/GSAPanimations.js"
import useDeviceDetect from "../components/deviceDetect.js"
import BruceWillisRocks from "../components/bruceWillisRocks"
import SallingGroup from "../components/sallingGroup"

const Portfolio = ({ location }) => {
  const { language } = useI18next()
  const prefersReducedMotion = usePrefersReducedMotion()
  const innerWidth =
    typeof window !== `undefined`
      ? window.innerWidth > 520
        ? 1.5342
        : (window.innerWidth - 32) * 0.003144
      : 1.5342
  const [scalingFactor, setScalingFactor] = useState(innerWidth)
  const { isMobile } = useDeviceDetect()

  useEffect(() => {
    let scrollStorage = window.localStorage.getItem("scroll")
    if (scrollStorage === null) {
      scrollStorage = 1
    } else if (scrollStorage < 20) {
      scrollStorage = JSON.parse(scrollStorage) + 1
    }
    window.localStorage.setItem("scroll", scrollStorage)
    const hand1 = document.querySelector("#hand1")
    const arrow1 = document.querySelector("#arrow1")
    const mouse = document.querySelector("#mouse")
    const scrollWheel = document.querySelector("#scrollWheel")
    const arrow2 = document.querySelector("#arrow2")
    const swipeDownAnim = document.querySelector("#swipeDownAnim")
    const ScrollDownAnim = document.querySelector("#ScrollDownAnim")
    if (isMobile === undefined) return
    if (isMobile && scrollStorage < 6) {
      swipeDown(hand1, arrow1, swipeDownAnim)
    } else if (scrollStorage < 6) {
      scrollDownMouse(mouse, scrollWheel, arrow2, ScrollDownAnim)
    }
  }, [isMobile])

  useEffect(() => {
    const blob1 = document.querySelector("[class*='blob1']")
    const blob2 = document.querySelector("[class*='blob2']")
    const blob3 = document.querySelector("[class*='cover']")
    const blob4 = document.querySelector("[class*='blob4']")
    const pageNum = document.querySelector("[class*='pageNum']")
    const dot1 = document.querySelector("[class*='dotPattern1']")
    const dot2 = document.querySelector("[class*='dotPattern2']")
    gsap.killTweensOf(blob1, blob2, blob3, blob4, pageNum, dot1, dot2)
    if (prefersReducedMotion === true) {
      return
    }
    floating(blob1, blob2, blob3, blob4, pageNum, dot1, dot2)
  }, [prefersReducedMotion])

  const { t } = useTranslation()

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  }, [])

  const handleResize = () => {
    let factor =
      window.innerWidth > 520 ? 1.4664 : (window.innerWidth - 32) * 0.003144
    setScalingFactor(factor)
  }

  return (
    <>
      <Seo
        title={t("PORTFOLIO.SEO_TITLE")}
        description={t("PORTFOLIO.SEO_DESCRIPTION")}
        pathName={location.pathname}
        image="/SoMe/open_graph_portfolio.png"
        readingTime="1"
      />
      <PreviousArrow to={language === "da" ? "/" : `/${language}/`} />
      <NextArrow
        to={language === "da" ? `/webudvikling/` : `/${language}/webudvikling/`}
      />
      <main className="bodyWrapper">
        <LanguageSwitch />
        <SoundButton />
        <ThemeSwitch />
        <SwipeDown />
        <ScrollDown />
        <section className="card">
          <Page02 className={styles.pageNumber} />
          <h1 className={styles.Display1}>{t("PORTFOLIO.DISPLAY")}</h1>
          <h2>{t("PORTFOLIO.HEADLINE")}</h2>
          <span>
            <p>{t("PORTFOLIO.BODY_TEXT_ONE")}</p>
            <ListTLink
              label={t("KOMPETENCER.DISPLAY")}
              to={language === "da" ? "/kompetencer/" : "/en/kompetencer/"}
              exit={{
                length: 0.4,
                trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
              }}
              entry={{
                delay: 0.4,
                appearAfter: 0.4,
                length: 0.4,
                trigger: ({ entry, node }) => transitionFadeIn(node),
              }}
            >
              {t("PORTFOLIO.GO_TO_GRAFIK")}
            </ListTLink>
            <p>{t("PORTFOLIO.BODY_TEXT_TWO")}</p>
            <ListTLink
              label={t("GRAFIK.DISPLAY")}
              to={language === "da" ? "/grafik/" : "/en/grafik/"}
              exit={{
                length: 0.4,
                trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
              }}
              entry={{
                delay: 0.4,
                appearAfter: 0.4,
                length: 0.4,
                trigger: ({ entry, node }) => transitionFadeIn(node),
              }}
            >
              {t("PORTFOLIO.GO_TO_GRAFIK")}
            </ListTLink>
            <p>{t("PORTFOLIO.BODY_TEXT_THREE")}</p>
          </span>
          <BlobGradient className={styles.blob1} />
          <Dotpattern className={styles.dotPattern1} />
        </section>
        <section className="card right" style={{ zIndex: "10" }}>
          <Blob className={styles.blob2} />
          <BlobClip className={styles.blob3} />
          <div className={styles.cover} />
          <Blob className={styles.blob4} />
          <Dotpattern className={styles.dotPattern2} />
        </section>
        <SallingGroup language={language} />
        <BruceWillisRocks language={language} />
        <section
          className="card wide"
          style={{
            minHeight: `calc(393px * ${scalingFactor} + 32px)`,
            overflowX: "visible",
            zIndex: "10",
            marginBottom: "120px",
          }}
        >
          <PhotoGrid scalingFactor={scalingFactor} />
        </section>
      </main>
    </>
  )
}

export default Portfolio

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
