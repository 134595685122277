// extracted by mini-css-extract-plugin
export var Display1 = "portfolio-module--Display1--ivIUi";
export var blob1 = "portfolio-module--blob1--dnkNF";
export var blob2 = "portfolio-module--blob2--JYL4q";
export var blob3 = "portfolio-module--blob3--CQtpE";
export var blob4 = "portfolio-module--blob4--ja5Dr";
export var cover = "portfolio-module--cover--CV1UF";
export var descriptionWrapper = "portfolio-module--descriptionWrapper--lmbrG";
export var dotPattern1 = "portfolio-module--dotPattern1--ul9c-";
export var dotPattern2 = "portfolio-module--dotPattern2--xBZZH";
export var effectImage = "portfolio-module--effectImage--DObph";
export var flexFrame = "portfolio-module--flexFrame--aaCUP";
export var github = "portfolio-module--github--mF6AK";
export var liMargin = "portfolio-module--liMargin--6dIq1";
export var lightning = "portfolio-module--lightning--2YIEn";
export var pageNumber = "portfolio-module--pageNumber--HwKyd";
export var paletteFrame = "portfolio-module--paletteFrame--RBEEZ";
export var photo = "portfolio-module--photo--UO+0F";
export var photoDescription = "portfolio-module--photoDescription--dvAKo";
export var photoGridContainer = "portfolio-module--photoGridContainer--q7uWb";
export var photoInFocus = "portfolio-module--photoInFocus--zNzpW";
export var right = "portfolio-module--right--14XmI";
export var sallingApp = "portfolio-module--sallingApp--t4aFw";
export var sallingGroupUrl = "portfolio-module--sallingGroupUrl--v3gYK";
export var shadeFrame = "portfolio-module--shadeFrame--b0Djk";
export var typographyFrame = "portfolio-module--typographyFrame--riyrG";
export var willisPageSpeed = "portfolio-module--willisPageSpeed--3ANmc";
export var willisUrl = "portfolio-module--willisUrl--jUObv";