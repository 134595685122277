import React, { useContext } from "react"
import * as styles from "../styles/portfolio.module.css"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import useSound from "use-sound"
import ButtonClick from "../sounds/button-click.mp3"
import { GlobalContext } from "../components/soundToggle"
import GitHub from "../assets/github_logo.inline.svg"

const SallingGroup = ({ language }) => {
  const { t } = useTranslation()
  const useSoundEnabled = useContext(GlobalContext)
  const soundEnabled = useSoundEnabled.soundEnabledStatus
  const [play] = useSound(ButtonClick, {
    volume: 0.25,
    soundEnabled: soundEnabled,
  })

  return (
    <>
      <section className="card right" style={{ marginTop: "64px" }}>
        <a
          onClick={play}
          href={
            language === "da"
              ? "https://sallinggroup.com/"
              : "https://en.sallinggroup.com/"
          }
          aria-label="Salling Group"
          title="Salling Group"
          target="_blank"
          rel="noreferrer noopener"
          className={styles.sallingGroupUrl}
        >
          <StaticImage
            className={styles.effectImage}
            imgClassName="noFilter"
            alt={t("PORTFOLIO.SALLING_GROUP_ALT")}
            src="../images/salling_group_digital.png"
            placeholder="blurred"
            layout="constrained"
            width={488}
            loading="lazy"
          />
        </a>
      </section>
      <section className="card left" style={{ marginRight: "16px" }}>
        <a name="sallinggroup" TabIndex={-1}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <h2 style={{ marginTop: "0" }}>
            {t("PORTFOLIO.SALLING_GROUP_HEADLINE")}
          </h2>
        </a>
        <p class="stylizedCapitalized">
          {t("PORTFOLIO.SALLING_GROUP_TAGLINE")}
        </p>
        <span>
          <p className="pre">{t("PORTFOLIO.SALLING_GROUP_PARAGRAPH")}</p>
          <a
            className="pre"
            href={
              language === "da"
                ? "https://sallinggroup.com/"
                : "https://en.sallinggroup.com/"
            }
            aria-label="Salling Group"
            title="Salling Group"
            target="_blank"
            rel="noreferrer noopener"
          >
            Salling Group
          </a>
          <p className="pre">.</p>
        </span>
        <ul>
          <li>{t("PORTFOLIO.SALLING_GROUP_LIST1")}</li>
          <li>
            {t("PORTFOLIO.SALLING_GROUP_LIST2")}
            <a
              href={"https://hjem.foetex.dk/"}
              target="_blank"
              rel="noreferrer noopener"
            >
              {t("PORTFOLIO.SALLING_GROUP_FØTEX_ALT")}
            </a>
          </li>
          <li>{t("PORTFOLIO.SALLING_GROUP_LIST3")}</li>
          <li>{t("PORTFOLIO.SALLING_GROUP_LIST4")}</li>
          <li className={styles.liMargin}>
            {t("PORTFOLIO.SALLING_GROUP_LIST5")}
          </li>
          <li className={styles.liMargin}>
            {t("PORTFOLIO.SALLING_GROUP_LIST6")}
          </li>
          <li className={styles.liMargin}>
            {t("PORTFOLIO.SALLING_GROUP_LIST7")}
          </li>
          <li className={styles.liMargin}>
            {t("PORTFOLIO.SALLING_GROUP_LIST8")}
          </li>
          <li className={styles.liMargin}>
            {t("PORTFOLIO.SALLING_GROUP_LIST9")}
          </li>
          <li className={styles.liMargin}>
            {t("PORTFOLIO.SALLING_GROUP_LIST10")}
          </li>
          <li className={styles.liMargin}>
            {t("PORTFOLIO.SALLING_GROUP_LIST11")}
          </li>
        </ul>
        <StaticImage
          alt={t("PORTFOLIO.SALLING_GROUP_APP_ALT")}
          className={styles.sallingApp}
          src="../images/salling_group_app.png"
          placeholder="blurred"
          layout="constrained"
          width={300}
          loading="lazy"
        />
      </section>
    </>
  )
}

export default SallingGroup
