import React, { useContext } from "react"
import * as styles from "../styles/portfolio.module.css"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import useSound from "use-sound"
import ButtonClick from "../sounds/button-click.mp3"
import { GlobalContext } from "../components/soundToggle"
import GitHub from "../assets/github_logo.inline.svg"

const BruceWillisRocks = ({ language }) => {
  const { t } = useTranslation()
  const useSoundEnabled = useContext(GlobalContext)
  const soundEnabled = useSoundEnabled.soundEnabledStatus
  const [play] = useSound(ButtonClick, {
    volume: 0.25,
    soundEnabled: soundEnabled,
  })

  return (
    <>
      <section className="card right" style={{ marginTop: "64px" }}>
        <a
          onClick={play}
          href={
            language === "da"
              ? "https://bruce-willis.rocks/"
              : "https://bruce-willis.rocks/en/"
          }
          aria-label={t("PORTFOLIO.BRUCE_TITLE")}
          title={t("PORTFOLIO.BRUCE_TITLE")}
          target="_blank"
          rel="noreferrer noopener"
          className={styles.willisUrl}
        >
          <StaticImage
            className={styles.effectImage}
            imgClassName="noFilter"
            alt={t("PORTFOLIO.BRUCE_WILLIS_ALT")}
            src="../images/bruce_willis_rocks.png"
            placeholder="blurred"
            layout="constrained"
            width={488}
            loading="lazy"
          />
        </a>
      </section>
      <section className="card left" style={{ marginRight: "16px" }}>
        <a name="brucewillis" TabIndex={-1}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <h2 style={{ marginTop: "0" }}>{t("PORTFOLIO.BRUCE_HEADLINE")}</h2>
        </a>
        <p class="stylizedCapitalized">{t("PORTFOLIO.BRUCE_TAGLINE")}</p>
        <ul>
          <li>{t("PORTFOLIO.BRUCE_LIST1")}</li>
          <li>{t("PORTFOLIO.BRUCE_LIST2")}</li>
          <li>{t("PORTFOLIO.BRUCE_LIST3")}</li>
        </ul>
        <StaticImage
          alt={t("PORTFOLIO.WILLIS_PAGE_SPEED_ALT")}
          className={styles.willisPageSpeed}
          src="../images/bruce_willis_pagespeed.png"
          placeholder="blurred"
          layout="constrained"
          width={200}
          loading="lazy"
        />
        <p className="mediumLabel" style={{ margin: "0.5rem 0 0 4em" }}>
          {t("PORTFOLIO.WILLIS_PAGE_SPEED_SOURCE")}
        </p>
        <div style={{ display: "flex" }}>
          <p>{t("PORTFOLIO.BRUCE_VISIT_GITHUB")}</p>
          <a
            title={t("PORTFOLIO.BRUCE_GITHUB_ALT")}
            aria-label={t("GITHUB_URL")}
            href="https://github.com/LarsEjaas/bruce-willis-app"
            target="_blank"
            rel="noreferrer noopener"
            className={styles.github + " goToGitHub"}
          >
            <GitHub
              className="github"
              onClick={play}
              role="button"
              style={{ marginBottom: "3px" }}
            />
          </a>
        </div>
      </section>
    </>
  )
}

export default BruceWillisRocks
